export const ArtistStatus = {
  NOT_CONFIRMED: "NOT_CONFIRMED",
  LOCKED: "LOCKED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  REVIEW_REQUIRED: "REVIEW_REQUIRED",
  REVIEW_SKIPPED: "REVIEW_SKIPPED",
  REJECTED_NOT_ARTIST: "REJECTED_NOT_ARTIST",
  REJECTED_US_LOCATION_UNSUPPORTED: "REJECTED_US_LOCATION_UNSUPPORTED",
  REJECTED_LOCATION_OUTSIDE_US: "REJECTED_LOCATION_OUTSIDE_US",
};
