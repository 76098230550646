import React, { useCallback } from "react";
import clsx from "clsx";

const specializations = [
  "Americana/American Traditional",
  "Abstract",
  "Black & Grey",
  "Blackwork",
  "Chicano",
  "Dotwork",
  "Fine-line",
  "Geometric",
  "Stick-and-poke",
  "Japanese/Irezumi",
  "Lettering/Script",
  "Neo-Traditional",
  "New School",
  "Ornamental",
  "Realism",
  "Illustrative",
  "Trash Polka",
  "Tribal",
  "Watercolor",
  "Surrealism",
  "Portrait",
  "Sketch",
  "Minimalist",
  "Horror",
  "Polynesian",
  "Biomechanical",
  "Floral",
];

const SpecializationControl = ({
  specialization,
  onSelect,
  onRemove,
  isSelected,
}) => {
  const handleOnClick = useCallback(() => {
    if (isSelected) {
      onRemove(specialization);
    } else {
      onSelect(specialization);
    }
  }, [isSelected, onRemove, onSelect, specialization]);

  const classNames = clsx("border border-2 px-2 py-1 m-1 text-sm", {
    "border-gray-400 text-gray-400": !isSelected,
    "border-indigo-800 text-indigo-800": isSelected,
  });

  return (
    <button className={classNames} type="button" onClick={handleOnClick}>
      {specialization}
    </button>
  );
};

export const SpecializationsControl = ({
  selectedSpecializations,
  onSelect,
  onRemove,
}) => {
  return (
    <div className="flex flex-row flex-wrap">
      {specializations.map((specialization) => (
        <SpecializationControl
          key={specialization}
          specialization={specialization}
          isSelected={selectedSpecializations.indexOf(specialization) > -1}
          onSelect={onSelect}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};
