import React from "react";
import { Dashboard } from "./components/Dashboard";
import { withAuthenticator } from "aws-amplify-react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import "@aws-amplify/ui/dist/style.css";
import "./styles/tailwind.generated.css";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/">
          <Dashboard />
        </Route>
        <Redirect to="/" />
      </Switch>
    </HashRouter>
  );
}

export default withAuthenticator(App, {
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
  },
});
