/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:92a8aa35-0949-480e-a6e9-7d4e11c1e7d4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VDGgunYqM",
    "aws_user_pools_web_client_id": "4rg8hi3kpapt24u7vsrbc3kdoh",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://xhcrjhdq3fepxati5zq6owcpsm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "tattdf8294923028e48008b8ee268a701d1d9133524-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
