import React from "react";
import { useAsyncImage } from "./useAsyncImage";
import clsx from "clsx";

export const ProfilePic = ({ documentKey }) => {
  const { url, isLoaded, onLoad } = useAsyncImage(documentKey);

  if (!url) {
    return (
      <div className="relative w-1/12 pt-1/12 h-0">
        <div className="absolute inset-0 m-2 bg-gray-400 rounded-full" />
      </div>
    );
  }

  const imgClassNames = clsx(
    "w-full rounded-full transition-opacity duration-300 ease-in-out",
    {
      "opacity-0": !isLoaded,
      "opacity-100": isLoaded,
    }
  );

  return (
    <div className="relative w-1/12 pt-1/12">
      <div className="absolute inset-0 m-2 flex items-center">
        <img onLoad={onLoad} className={imgClassNames} src={url} alt="" />
      </div>
    </div>
  );
};
