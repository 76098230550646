import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNextArtistByStatus, getArtistsByStatus, updateArtist } from "./api";
import { ArtistLoader } from "./ArtistLoader";
import { ArtistStatus } from "../../models/ArtistStatus";
import { ImageDetailStatus } from "../../models/ImageDetailStatus";
import { useAuth } from "../../utils/auth";

export const ArtistsDashboard = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const { isAdmin, isSuperAdmin } = useAuth();

  const onConfirmArtistsProfiles = useCallback(async () => {
    setIsLoading(true);
    const nextArtist = await getNextArtistByStatus(ArtistStatus.NOT_CONFIRMED);
    if (nextArtist) {
      history.push(
        `/artists/${nextArtist.id}?status=${ArtistStatus.NOT_CONFIRMED}`
      );
    } else {
      alert("There are no artists to confirm");
      setIsLoading(false);
    }
  }, [history]);

  const onReviewRejectedArtistsProfiles = useCallback(async () => {
    setIsLoading(true);
    const nextArtist = await getNextArtistByStatus(ArtistStatus.REJECTED);
    if (nextArtist) {
      history.push(`/artists/${nextArtist.id}?status=${ArtistStatus.REJECTED}`);
    } else {
      alert("There are no rejected artists");
      setIsLoading(false);
    }
  }, [history]);

  const onReviewArtistsProfiles = useCallback(async () => {
    setIsLoading(true);
    const nextArtist = await getNextArtistByStatus(
      ArtistStatus.REVIEW_REQUIRED
    );
    if (nextArtist) {
      history.push(
        `/artists/${nextArtist.id}?status=${ArtistStatus.REVIEW_REQUIRED}`
      );
    } else {
      alert("There are no artists to review");
      setIsLoading(false);
    }
  }, [history]);

  const onResetArtists = useCallback(
    async (artists) =>
      Promise.all(
        artists.map((artist) => {
          artist.images_details.forEach(
            (imageDetail) =>
              (imageDetail.status = ImageDetailStatus.NOT_CONFIRMED)
          );
          artist.status = ArtistStatus.NOT_CONFIRMED;
          return updateArtist(artist);
        })
      ),
    []
  );

  const resetArtistsWithStatus = useCallback(
    async (status) => {
      const artists = await getArtistsByStatus({ status, limit: 10 });
      await onResetArtists(artists);
      if (artists.length === 10) {
        await resetArtistsWithStatus(status);
      }
    },
    [onResetArtists]
  );

  const onResetLockedArtists = useCallback(async () => {
    setIsLoading(true);
    await resetArtistsWithStatus(ArtistStatus.LOCKED);
    setIsLoading(false);
  }, [resetArtistsWithStatus]);

  if (isLoading) {
    return <ArtistLoader />;
  }

  return (
    <div className="flex flex-row h-screen">
      <div className="flex flex-col w-1/3 px-2">
        <button
          type="button"
          onClick={onConfirmArtistsProfiles}
          className="w-full bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-4 px-8 my-2"
        >
          Confirm artists profiles
        </button>
        <button
          type="button"
          onClick={onReviewRejectedArtistsProfiles}
          className="w-full bg-orange-500 hover:bg-orange-700 text-white font-bold py-4 px-8 my-2"
        >
          Review rejected profiles
        </button>
        {isAdmin && (
          <button
            type="button"
            onClick={onReviewArtistsProfiles}
            className="w-full bg-orange-500 hover:bg-orange-700 text-white font-bold py-4 px-8 my-2"
          >
            Review required [@Laura]
          </button>
        )}
        {isSuperAdmin && (
          <button
            type="button"
            onClick={onResetLockedArtists}
            className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-8 my-2"
          >
            Reset locked artists
          </button>
        )}
      </div>
      <div className="flex flex-col w-2/3 p-2">
        <div className="w-full h-full">
          <iframe
            title="Counters status"
            src="https://search-amplify-elasti-xjiud9wdx3uu-whibe34nxgujm6wkf7fgk4giua.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/a04c6d50-dceb-11ea-816f-6b16da75278a?embed=true"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
