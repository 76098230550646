import Auth from "@aws-amplify/auth";
import { UserGroup } from "../models/UserGroup";
import { useState, useEffect, useCallback } from "react";

const getUserInfo = () => Auth.currentUserInfo();

export const getUserUsername = async () => {
  const userInfo = await getUserInfo();
  return userInfo.username;
};

const getCurrentAuthenticatedUser = () => Auth.currentAuthenticatedUser();

const getUserGroups = async () => {
  const user = await getCurrentAuthenticatedUser();
  console.log(user);
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  return groups || [];
};

export const useAuth = () => {
  const [groups, setGroups] = useState({
    isSuperAdmin: false,
    isAdmin: false,
    isModerator: false,
  });

  const init = useCallback(async () => {
    const userGroups = await getUserGroups();
    setGroups({
      isSuperAdmin: userGroups.indexOf(UserGroup.SuperAdmins) > -1,
      isAdmin: userGroups.indexOf(UserGroup.Admins) > -1,
      isModerator: userGroups.indexOf(UserGroup.Moderators) > -1,
    });
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return groups;
};
