import React, { useEffect, useState, useCallback, useRef } from "react";
import { CitiesControl } from "./CitiesControl";
import { ProfilePic } from "./ProfilePic";
import { ImageDetailsControl } from "./ImageDetailsControl";
import { SpecializationsControl } from "./SpecializationsControl";
import { useParams, useHistory } from "react-router-dom";
import { getNextArtistByStatus, getArtist, updateArtist } from "../api";
import { ArtistStatus } from "../../../models/ArtistStatus";
import { ImageDetailStatus } from "../../../models/ImageDetailStatus";
import { extractEmails } from "../../../utils/email";
import { ArtistLoader } from "../ArtistLoader";
import { useQuery } from "./useQuery";

const setImageDetailsStatus = (artist) =>
  artist.images_details?.forEach((imageDetail) => {
    if (
      !imageDetail.status ||
      imageDetail.status === ImageDetailStatus.NOT_CONFIRMED
    ) {
      imageDetail.status = ImageDetailStatus.CONFIRMED;
    }
  });

const setEmail = (artist) => {
  if (!artist.email) {
    const [email] = extractEmails(artist.bio);
    if (email) {
      artist.email = email;
    }
  }
};

export const ArtistEditor = () => {
  const { id } = useParams();

  const history = useHistory();

  const query = useQuery();

  const [artist, setArtist] = useState(null);

  const preloadedArtistRef = useRef(null);

  const [isUpdating, setIsUpdating] = useState();

  const status = query.get("status") || ArtistStatus.NOT_CONFIRMED;

  const isStatusReviewRequired = status === ArtistStatus.REVIEW_REQUIRED;

  const isStatusRejected = status === ArtistStatus.REJECTED;

  const showSpecificRejectReason = isStatusRejected || isStatusReviewRequired;

  const getAndLockArtist = useCallback(async () => {
    const { current: preloadedArtist } = preloadedArtistRef;
    const nextArtist =
      !preloadedArtist || preloadedArtist.id !== id
        ? await getArtist(id)
        : preloadedArtist;
    if (nextArtist.status === ArtistStatus.NOT_CONFIRMED) {
      return updateArtist({ ...nextArtist, status: ArtistStatus.LOCKED });
    }
    return nextArtist;
  }, [id]);

  const resetArtist = useCallback(async () => {
    const nextArtist = await getAndLockArtist();
    setImageDetailsStatus(nextArtist);
    setEmail(nextArtist);
    setArtist(nextArtist);
    setIsUpdating(false);
  }, [getAndLockArtist]);

  useEffect(() => {
    resetArtist();
  }, [id, resetArtist]);

  const onCitySelect = useCallback((city) => {
    setArtist((state) => ({ ...state, hashtag_location: city }));
  }, []);

  const onToggleImage = useCallback((imageDetailId) => {
    setArtist((state) => ({
      ...state,
      images_details: state.images_details.map((imageDetail) => {
        if (imageDetail.id === imageDetailId) {
          const imageDetailsStatus =
            imageDetail.status === ImageDetailStatus.CONFIRMED
              ? ImageDetailStatus.REJECTED
              : ImageDetailStatus.CONFIRMED;
          return { ...imageDetail, status: imageDetailsStatus };
        }
        return imageDetail;
      }),
    }));
  }, []);

  const onAddSpecialization = useCallback((specialization) => {
    setArtist((state) => ({
      ...state,
      specializations: [...state.specializations, specialization],
    }));
  }, []);

  const onRemoveSpecialization = useCallback((specialization) => {
    setArtist((state) => ({
      ...state,
      specializations: state.specializations.filter(
        (oldSpecialization) => oldSpecialization !== specialization
      ),
    }));
  }, []);

  const proceedToNextArtist = useCallback(async () => {
    const nextArtist = await getNextArtistByStatus(status);
    if (nextArtist) {
      preloadedArtistRef.current = nextArtist;
      history.push(`/artists/${nextArtist.id}?status=${status}`);
    } else {
      alert("There are no more artists");
      history.push("/artists/dashboard");
    }
  }, [history, status]);

  const onUpdateArtistStatus = useCallback(
    async (updatedStatus) => {
      setIsUpdating(true);
      await updateArtist({ ...artist, status: updatedStatus });
      proceedToNextArtist();
    },
    [artist, proceedToNextArtist]
  );

  const onReview = useCallback(
    () => onUpdateArtistStatus(ArtistStatus.REVIEW_REQUIRED),
    [onUpdateArtistStatus]
  );

  const onReviewSkip = useCallback(
    () => onUpdateArtistStatus(ArtistStatus.REVIEW_SKIPPED),
    [onUpdateArtistStatus]
  );

  const onReject = useCallback(
    () => onUpdateArtistStatus(ArtistStatus.REJECTED),
    [onUpdateArtistStatus]
  );

  const onRejectNotAnArtist = useCallback(
    () => onUpdateArtistStatus(ArtistStatus.REJECTED_NOT_ARTIST),
    [onUpdateArtistStatus]
  );

  const onRejectUsLocationUnsuppoted = useCallback(
    () => onUpdateArtistStatus(ArtistStatus.REJECTED_US_LOCATION_UNSUPPORTED),
    [onUpdateArtistStatus]
  );

  const onRejectLocationOutsideUs = useCallback(
    () => onUpdateArtistStatus(ArtistStatus.REJECTED_LOCATION_OUTSIDE_US),
    [onUpdateArtistStatus]
  );

  const onAccept = useCallback(
    () => onUpdateArtistStatus(ArtistStatus.ACCEPTED),
    [onUpdateArtistStatus]
  );

  const handleOnEmailChange = useCallback((event) => {
    const email = event.target.value;
    setArtist((state) => ({
      ...state,
      email,
    }));
  }, []);

  if (!artist || isUpdating) {
    return <ArtistLoader />;
  }

  return (
    <div className="container mx-auto">
      <CitiesControl
        onSelect={onCitySelect}
        selectedCity={artist.hashtag_location}
      />
      <div className="flex flex-row items-center">
        <ProfilePic documentKey={artist.profile_pic.path} />
        <div className="flex w-2/3 flex-col p-4">
          {artist.location && artist.location.city && (
            <p className="text-lg text-indigo-800">{artist.location.city}</p>
          )}
          <div>
            <a
              className="text-lg text-blue-500 no-underline hover:underline"
              href={artist.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {artist.link}
            </a>
          </div>
          {artist.full_name && (
            <p className="text-lg font-bold text-gray-800">
              {artist.full_name}
            </p>
          )}
          <p className="text-lg text-gray-600">{artist.bio}</p>
        </div>
        <div className="flex flex-col w-1/4 items-center justify-center">
          <button
            type="button"
            onClick={onAccept}
            disabled={isUpdating}
            className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-4 px-8 m-2 rounded"
          >
            Accept
          </button>
          <label className="flex flex-col  m-2 w-full">
            <p className="text-sm text-gray-600">Email</p>
            <input
              type="text"
              className="flex-grow px-4 py-2 border border-gray-400 text-sm text-gray-800"
              value={artist.email || ""}
              onChange={handleOnEmailChange}
            />
          </label>
        </div>
      </div>
      <SpecializationsControl
        selectedSpecializations={artist.specializations}
        onSelect={onAddSpecialization}
        onRemove={onRemoveSpecialization}
      />
      <ImageDetailsControl
        imageDetails={artist.images_details}
        onSelect={onToggleImage}
      />
      <div className="flex flex-row">
        {!isStatusReviewRequired && (
          <button
            type="button"
            onClick={onReview}
            disabled={isUpdating}
            className="w-1/3 mx-auto my-12 bg-orange-500 hover:bg-orange-700 text-white font-bold py-4 px-8 rounded"
          >
            Review
          </button>
        )}
        {isStatusReviewRequired && (
          <button
            type="button"
            onClick={onReviewSkip}
            disabled={isUpdating}
            className="w-1/3 mx-auto my-12 bg-orange-500 hover:bg-yellow-700 text-white font-bold py-4 px-8 rounded"
          >
            Skip Review
          </button>
        )}
        {!showSpecificRejectReason && (
          <button
            type="button"
            onClick={onReject}
            disabled={isUpdating}
            className="w-1/3 mx-auto my-12 bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-8 rounded"
          >
            Reject
          </button>
        )}
      </div>
      {showSpecificRejectReason && (
        <div className="flex flex-col">
          <h2 className="text-bold">Why did we reject this profile?</h2>
          <button
            type="button"
            onClick={onRejectNotAnArtist}
            disabled={isUpdating}
            className="mx-auto my-2 bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-8 rounded"
          >
            Profile is not a tatoo artist
          </button>
          <button
            type="button"
            onClick={onRejectUsLocationUnsuppoted}
            disabled={isUpdating}
            className="mx-auto my-2 bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-8 rounded"
          >
            Is tatoo artist in US but location is not supported
          </button>
          <button
            type="button"
            onClick={onRejectLocationOutsideUs}
            disabled={isUpdating}
            className="mx-auto my-2 bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-8 rounded"
          >
            Is tatoo artist but location is outside US
          </button>
        </div>
      )}
    </div>
  );
};
