import React from "react";
import { ArtistsRoutes } from "./artists/ArtistsRoutes";
import { Switch, Route, Redirect } from "react-router-dom";
import { SignOut } from "aws-amplify-react";

export const Dashboard = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-end p-2 border-b-2">
        <SignOut />
      </div>
      <Switch>
        <Route path="/artists">
          <ArtistsRoutes />
        </Route>
        <Redirect to="/artists" />
      </Switch>
    </div>
  );
};
