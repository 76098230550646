import React, { useCallback } from "react";
import { useAsyncImage } from "./useAsyncImage";
import clsx from "clsx";
import { ImageDetailStatus } from "../../../models/ImageDetailStatus";

export const ImageDetail = ({ documentKey, title, status, id, onClick }) => {
  const { url, isLoaded, onLoad } = useAsyncImage(documentKey);

  const handleOnClick = useCallback(() => onClick(id), [id, onClick]);

  if (!url) {
    return (
      <div className="relative w-1/6 pt-1/6">
        <div className="absolute inset-0 m-2 bg-gray-400" />
      </div>
    );
  }

  const buttonClassNames = clsx(
    "absolute top-0 bottom-0 transition-opacity duration-300 ease-in-out",
    {
      "opacity-0": !isLoaded,
      "opacity-25": status === ImageDetailStatus.REJECTED && isLoaded,
      "opacity-100": status === ImageDetailStatus.CONFIRMED && isLoaded,
    }
  );

  return (
    <div className="relative w-1/6 pt-1/6">
      {!isLoaded && <div className="absolute inset-0 z-10 m-2 bg-gray-400" />}
      <div className="absolute inset-0 m-2 flex items-center overflow-hidden">
        <button
          onClick={handleOnClick}
          type="button"
          className={buttonClassNames}
        >
          <img onLoad={onLoad} className="w-full " src={url} alt="" />
          {title && (
            <div className="absolute bottom-0 w-full text-base text-white bg-gray-800 text-center max-h-3 px-1 overflow-hidden">
              {title}
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export const ImageDetailsControl = ({ imageDetails, onSelect }) => {
  return (
    <div className="flex flex-row flex-wrap">
      {imageDetails.map((image_detail) => (
        <ImageDetail
          onClick={onSelect}
          key={image_detail.id}
          documentKey={image_detail.path}
          id={image_detail.id}
          status={image_detail.status}
          title={image_detail.specializations.join(", ")}
        />
      ))}
    </div>
  );
};
