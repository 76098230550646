/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInstagramArtist = /* GraphQL */ `
  query GetInstagramArtist($id: ID!) {
    getInstagramArtist(id: $id) {
      id
      bio
      business_category_name
      full_name
      link
      overall_category_name
      username
      images_details {
        id
        caption
        checksum
        path
        shortcode
        specializations
        url
        status
      }
      location {
        id
        city
        coordinates {
          lat
          lng
        }
      }
      profile_pic {
        checksum
        path
        url
      }
      specializations
      modifiedBy
      status
      email
      hashtag_location {
        code
        description
      }
      yelp_alias_id
      createdAt
      updatedAt
    }
  }
`;
export const listInstagramArtists = /* GraphQL */ `
  query ListInstagramArtists(
    $filter: ModelInstagramArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstagramArtists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bio
        business_category_name
        full_name
        link
        overall_category_name
        username
        images_details {
          id
          caption
          checksum
          path
          shortcode
          specializations
          url
          status
        }
        location {
          id
          city
        }
        profile_pic {
          checksum
          path
          url
        }
        specializations
        modifiedBy
        status
        email
        hashtag_location {
          code
          description
        }
        yelp_alias_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getYelpStudio = /* GraphQL */ `
  query GetYelpStudio($id: ID!) {
    getYelpStudio(id: $id) {
      id
      name
      instagram_link
      image_url
      phone
      friendly_phone
      categories
      yelp_rating
      address1
      address2
      address3
      city
      state
      zip
      cross_streets
      latitude
      longitude
      photos {
        checksum
        path
        url
      }
      price
      sunday_open
      sunday_close
      monday_open
      monday_close
      tuesday_open
      tuesday_close
      wednesday_open
      wednesday_close
      thursday_open
      thursday_close
      friday_open
      friday_close
      saturday_open
      saturday_close
      modifiedBy
      status
      email
      profile_pic {
        checksum
        path
        url
      }
      artists {
        items {
          id
          bio
          business_category_name
          full_name
          link
          overall_category_name
          username
          specializations
          modifiedBy
          status
          email
          yelp_alias_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listYelpStudios = /* GraphQL */ `
  query ListYelpStudios(
    $filter: ModelYelpStudioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYelpStudios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        instagram_link
        image_url
        phone
        friendly_phone
        categories
        yelp_rating
        address1
        address2
        address3
        city
        state
        zip
        cross_streets
        latitude
        longitude
        photos {
          checksum
          path
          url
        }
        price
        sunday_open
        sunday_close
        monday_open
        monday_close
        tuesday_open
        tuesday_close
        wednesday_open
        wednesday_close
        thursday_open
        thursday_close
        friday_open
        friday_close
        saturday_open
        saturday_close
        modifiedBy
        status
        email
        profile_pic {
          checksum
          path
          url
        }
        artists {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const artistByStatus = /* GraphQL */ `
  query ArtistByStatus(
    $status: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInstagramArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    artistByStatus(
      status: $status
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bio
        business_category_name
        full_name
        link
        overall_category_name
        username
        images_details {
          id
          caption
          checksum
          path
          shortcode
          specializations
          url
          status
        }
        location {
          id
          city
        }
        profile_pic {
          checksum
          path
          url
        }
        specializations
        modifiedBy
        status
        email
        hashtag_location {
          code
          description
        }
        yelp_alias_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchInstagramArtists = /* GraphQL */ `
  query SearchInstagramArtists(
    $filter: SearchableInstagramArtistFilterInput
    $sort: SearchableInstagramArtistSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchInstagramArtists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bio
        business_category_name
        full_name
        link
        overall_category_name
        username
        images_details {
          id
          caption
          checksum
          path
          shortcode
          specializations
          url
          status
        }
        location {
          id
          city
        }
        profile_pic {
          checksum
          path
          url
        }
        specializations
        modifiedBy
        status
        email
        hashtag_location {
          code
          description
        }
        yelp_alias_id
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchYelpStudios = /* GraphQL */ `
  query SearchYelpStudios(
    $filter: SearchableYelpStudioFilterInput
    $sort: SearchableYelpStudioSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchYelpStudios(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        instagram_link
        image_url
        phone
        friendly_phone
        categories
        yelp_rating
        address1
        address2
        address3
        city
        state
        zip
        cross_streets
        latitude
        longitude
        photos {
          checksum
          path
          url
        }
        price
        sunday_open
        sunday_close
        monday_open
        monday_close
        tuesday_open
        tuesday_close
        wednesday_open
        wednesday_close
        thursday_open
        thursday_close
        friday_open
        friday_close
        saturday_open
        saturday_close
        modifiedBy
        status
        email
        profile_pic {
          checksum
          path
          url
        }
        artists {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
