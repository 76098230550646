import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const useQuery = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  return query;
};
