import React from "react";
import { ArtistEditor } from "./ArtistEditor/ArtistEditor";
import { Switch, Route, Redirect } from "react-router-dom";
import { ArtistsDashboard } from "./ArtistsDashboard";

export const ArtistsRoutes = () => {
  return (
    <Switch>
      <Route path="/artists/dashboard">
        <ArtistsDashboard />
      </Route>
      <Route path="/artists/:id">
        <ArtistEditor />
      </Route>
      <Redirect to="/artists/dashboard" />
    </Switch>
  );
};
