import { useEffect, useState, useCallback } from "react";
import { getDocumentUrl } from "../../../utils/storage";

export const useAsyncImage = (documentKey) => {
  const [url, setUrl] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const loadDocument = useCallback(async () => {
    setIsLoaded(false);
    if (documentKey) {
      setUrl(await getDocumentUrl(documentKey));
    }
  }, [documentKey]);

  useEffect(() => {
    loadDocument();
  }, [loadDocument]);

  const onLoad = useCallback(() => setIsLoaded(true), []);

  return {
    url,
    isLoaded,
    onLoad,
  };
};
