import API, { graphqlOperation } from "@aws-amplify/api";
import { getInstagramArtist, artistByStatus } from "../../graphql/queries";
import { updateInstagramArtist } from "../../graphql/mutations";

import { omit } from "lodash";
import { getUserUsername } from "../../utils/auth";

const removeAutogeneratedProperties = (input) =>
  omit(input, ["createdAt", "updatedAt"]);

const addAuthProperties = async (input) => {
  const username = await getUserUsername();
  return { ...input, modifiedBy: username };
};

const prepareInput = async (input) => {
  const inputWithAuthProperties = await addAuthProperties(input);
  return removeAutogeneratedProperties(inputWithAuthProperties);
};

const errorHandler = (e) => alert("Something went terribly wrong:", e);

export const getNextArtistByStatus = async (status) => {
  try {
    const [nextArtist = null] = await getArtistsByStatus({ status, limit: 1 });
    return nextArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getArtist = async (id) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getInstagramArtist, { id })
    );
    return data.getInstagramArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const updateArtist = async (artist) => {
  try {
    const input = await prepareInput(artist);
    const { data } = await API.graphql(
      graphqlOperation(updateInstagramArtist, { input })
    );
    return data.updateInstagramArtist;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};

export const getArtistsByStatus = async (variables = {}) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(artistByStatus, variables)
    );
    return data.artistByStatus.items;
  } catch (e) {
    errorHandler(e);
    throw e;
  }
};
