import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import AWSConfig from "./aws-exports";
import "./index.css";

Amplify.configure(AWSConfig);
Auth.configure(AWSConfig);
API.configure(AWSConfig);
Storage.configure(AWSConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
