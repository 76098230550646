import React, { useCallback } from "react";
import clsx from "clsx";

const cities = [
  { code: "OAK", description: "Oakland, California" },
  { code: "ATX", description: "Austin, Texas" },
  { code: "CHI", description: "Chicago, Illinois" },
  { code: "DEN", description: "Denver, Colorado" },
  { code: "HNL", description: "Honolulu, Hawaii" },
  { code: "HOU", description: "Houston, Texas" },
  { code: "LA", description: "Los Angeles, California" },
  { code: "LAS", description: "Las Vegas, Nevada" },
  { code: "MIA", description: "Miami, Florida" },
  { code: "SF", description: "San Francisco, California" },
  { code: "NYC", description: "New York City, New York" },
  { code: "SD", description: "San Diego, California" },
  { code: "PDX", description: "Portland, Oregon" },
];

const CityControl = ({ city, isSelected, onClick }) => {
  const handleOnClick = useCallback(() => onClick(city), [city, onClick]);
  const classNames = clsx("flex flex-col m-1 px-2 py-1 items-center border-2", {
    "border-transparent text-gray-800": !isSelected,
    "border-indigo-800 text-indigo-800": isSelected,
  });
  return (
    <button
      type="button"
      className={classNames}
      onClick={handleOnClick}
      title={city.description}
    >
      <p className="text-base font-bold">{city.code}</p>
    </button>
  );
};

export const CitiesControl = ({ selectedCity, onSelect }) => {
  return (
    <div className="flex flex-row flex-wrap mb-2">
      {cities.map((city) => (
        <div key={city.code} className="w-1/13">
          <CityControl
            city={city}
            isSelected={city.code === selectedCity.code}
            onClick={onSelect}
          />
        </div>
      ))}
    </div>
  );
};
