/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInstagramArtist = /* GraphQL */ `
  mutation CreateInstagramArtist(
    $input: CreateInstagramArtistInput!
    $condition: ModelInstagramArtistConditionInput
  ) {
    createInstagramArtist(input: $input, condition: $condition) {
      id
      bio
      business_category_name
      full_name
      link
      overall_category_name
      username
      images_details {
        id
        caption
        checksum
        path
        shortcode
        specializations
        url
        status
      }
      location {
        id
        city
        coordinates {
          lat
          lng
        }
      }
      profile_pic {
        checksum
        path
        url
      }
      specializations
      modifiedBy
      status
      email
      hashtag_location {
        code
        description
      }
      yelp_alias_id
      createdAt
      updatedAt
    }
  }
`;
export const updateInstagramArtist = /* GraphQL */ `
  mutation UpdateInstagramArtist(
    $input: UpdateInstagramArtistInput!
    $condition: ModelInstagramArtistConditionInput
  ) {
    updateInstagramArtist(input: $input, condition: $condition) {
      id
      bio
      business_category_name
      full_name
      link
      overall_category_name
      username
      images_details {
        id
        caption
        checksum
        path
        shortcode
        specializations
        url
        status
      }
      location {
        id
        city
        coordinates {
          lat
          lng
        }
      }
      profile_pic {
        checksum
        path
        url
      }
      specializations
      modifiedBy
      status
      email
      hashtag_location {
        code
        description
      }
      yelp_alias_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteInstagramArtist = /* GraphQL */ `
  mutation DeleteInstagramArtist(
    $input: DeleteInstagramArtistInput!
    $condition: ModelInstagramArtistConditionInput
  ) {
    deleteInstagramArtist(input: $input, condition: $condition) {
      id
      bio
      business_category_name
      full_name
      link
      overall_category_name
      username
      images_details {
        id
        caption
        checksum
        path
        shortcode
        specializations
        url
        status
      }
      location {
        id
        city
        coordinates {
          lat
          lng
        }
      }
      profile_pic {
        checksum
        path
        url
      }
      specializations
      modifiedBy
      status
      email
      hashtag_location {
        code
        description
      }
      yelp_alias_id
      createdAt
      updatedAt
    }
  }
`;
export const createYelpStudio = /* GraphQL */ `
  mutation CreateYelpStudio(
    $input: CreateYelpStudioInput!
    $condition: ModelYelpStudioConditionInput
  ) {
    createYelpStudio(input: $input, condition: $condition) {
      id
      name
      instagram_link
      image_url
      phone
      friendly_phone
      categories
      yelp_rating
      address1
      address2
      address3
      city
      state
      zip
      cross_streets
      latitude
      longitude
      photos {
        checksum
        path
        url
      }
      price
      sunday_open
      sunday_close
      monday_open
      monday_close
      tuesday_open
      tuesday_close
      wednesday_open
      wednesday_close
      thursday_open
      thursday_close
      friday_open
      friday_close
      saturday_open
      saturday_close
      modifiedBy
      status
      email
      profile_pic {
        checksum
        path
        url
      }
      artists {
        items {
          id
          bio
          business_category_name
          full_name
          link
          overall_category_name
          username
          specializations
          modifiedBy
          status
          email
          yelp_alias_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateYelpStudio = /* GraphQL */ `
  mutation UpdateYelpStudio(
    $input: UpdateYelpStudioInput!
    $condition: ModelYelpStudioConditionInput
  ) {
    updateYelpStudio(input: $input, condition: $condition) {
      id
      name
      instagram_link
      image_url
      phone
      friendly_phone
      categories
      yelp_rating
      address1
      address2
      address3
      city
      state
      zip
      cross_streets
      latitude
      longitude
      photos {
        checksum
        path
        url
      }
      price
      sunday_open
      sunday_close
      monday_open
      monday_close
      tuesday_open
      tuesday_close
      wednesday_open
      wednesday_close
      thursday_open
      thursday_close
      friday_open
      friday_close
      saturday_open
      saturday_close
      modifiedBy
      status
      email
      profile_pic {
        checksum
        path
        url
      }
      artists {
        items {
          id
          bio
          business_category_name
          full_name
          link
          overall_category_name
          username
          specializations
          modifiedBy
          status
          email
          yelp_alias_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteYelpStudio = /* GraphQL */ `
  mutation DeleteYelpStudio(
    $input: DeleteYelpStudioInput!
    $condition: ModelYelpStudioConditionInput
  ) {
    deleteYelpStudio(input: $input, condition: $condition) {
      id
      name
      instagram_link
      image_url
      phone
      friendly_phone
      categories
      yelp_rating
      address1
      address2
      address3
      city
      state
      zip
      cross_streets
      latitude
      longitude
      photos {
        checksum
        path
        url
      }
      price
      sunday_open
      sunday_close
      monday_open
      monday_close
      tuesday_open
      tuesday_close
      wednesday_open
      wednesday_close
      thursday_open
      thursday_close
      friday_open
      friday_close
      saturday_open
      saturday_close
      modifiedBy
      status
      email
      profile_pic {
        checksum
        path
        url
      }
      artists {
        items {
          id
          bio
          business_category_name
          full_name
          link
          overall_category_name
          username
          specializations
          modifiedBy
          status
          email
          yelp_alias_id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
